import { GetStaticPropsContext } from "next";
import Link from "next/link";

import { graphqlFetch } from "@shared/hooks";
import { CallsFilterVisibilityStatus } from "@shared/types";

import { TWizardCallsQuery, WizardCallsQuery } from "__generated__/types";

import * as constants from "src/constants";
import bundleMessages from "src/utils/bundleMessages";

export default function Home({ calls }: { calls: TWizardCallsQuery["calls"] }) {
  // TODO: This route is a no-op. Remove it and redirect to the main app.
  return (
    <main>
      <h1>Hello wizard!</h1>
      <ul>
        {calls.map(
          (call) =>
            call && (
              <li key={call.id} className="py-1">
                <Link className="text-primary-600" href={`/${call.slug}`}>
                  {call.title.en} {call.allowsRevocation ? "✍️ " : " "}
                </Link>
                <details className="text-[10px] text-primary-400 cursor-pointer">
                  <summary>Call Details</summary>
                  {call.categories[0].singleImagesCategory
                    ? "Single Image Category"
                    : `Projects: Min ${call.categories[0].minProjects} - Max ${call.categories[0].maxProjects}`}
                  <pre>{JSON.stringify(call, null, 2)}</pre>
                </details>
              </li>
            ),
        )}
      </ul>
    </main>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = await bundleMessages(locale, [
    `${constants.MESSAGE_PREFIX}pages.index`,
  ]);

  const { calls }: TWizardCallsQuery = await graphqlFetch(
    JSON.stringify({
      query: WizardCallsQuery,
      variables: {
        limit: 100,
        filter: { visibilityStatus: CallsFilterVisibilityStatus.PUBLIC },
      },
    }),
  );

  // Only show MVP calls
  const filteredCalls = calls.filter((call) => call?.title.en.includes("MVP"));

  return {
    props: {
      messages,
      calls: filteredCalls,
    },
    revalidate: 60,
  };
}
